import { useForceUpdate } from 'app/hooks/use-force-update.hook';
import { useSubscription } from 'app/hooks/use-subscription.hook';

export const useUpdateOnFormChanges = (formGroup: CHRFormControl) => {
  const forceUpdate = useForceUpdate();

  useSubscription(formGroup?.changes, forceUpdate, [formGroup]);
};

export const useUpdateOnFormTouchedChanges = (formGroup: CHRFormControl) => {
  const forceUpdate = useForceUpdate();

  useSubscription(formGroup?.touchedChanges, forceUpdate, [formGroup]);
};

export const useUpdateOnFormValidationChanges = (formGroup: CHRFormControl) => {
  const forceUpdate = useForceUpdate();

  useSubscription(formGroup?.validationChanges, forceUpdate, [formGroup]);
};

export const useSubscribeToFormValueChanges = (formGroup: CHRFormControl) => {
  const forceUpdate = useForceUpdate();

  useSubscription(formGroup?.valueChanges, forceUpdate, [formGroup]);
};

export const useSubscribeToFormDisabledChanges = (formGroup: CHRFormControl) => {
  const forceUpdate = useForceUpdate();

  useSubscription(formGroup?.disabledChanges, forceUpdate, [formGroup]);
};
